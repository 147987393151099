import { SUPPORT_BSC } from 'config/constants/supportChains'
import { CHAIN_IDS } from 'utils/wagmi'
import Swap from '../views/Swap'

const SwapPage = () => {
  return <Swap />
}

SwapPage.chains = SUPPORT_BSC

export default SwapPage
